import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/signin",
    name: "SignIn",
    component: () => import(/* webpackChunkName: "SignIn" */ "../views/SignInPage.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/HomePage.vue"),
    meta: {
      requiresAuth: true,
      breadCrumb: [
        {
          name: "PG Dashboard",
        },
      ],
    },
  },
  {
    path: "/debug-autocomplete",
    name: "DebugAutocomplete",
    component: () =>
      import(/* webpackChunkName: "DebugAutocomplete" */ "../views/DebugAutocomplete.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "DebugAutocomplete",
            name: "Debug Autocomplete",
          },
        ];
      },
    },
  },
  {
    path: "/debug-provider-match",
    name: "DebugProviderMatch",
    component: () =>
      import(/* webpackChunkName: "DebugProviderMatch" */ "../views/DebugProviderMatch.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "DebugProviderMatch",
            name: "Debug Provider Match",
          },
        ];
      },
    },
  },
  {
    path: "/organizations",
    name: "Organizations",
    component: () =>
      import(/* webpackChunkName: "Organizations" */ "../views/OrganizationsPage.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "Organizations",
            name: "Organizations",
          },
        ];
      },
    },
  },
  {
    path: "/organizations/:id",
    name: "Organization",
    component: () => import(/* webpackChunkName: "Organization" */ "../views/OrganizationPage.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            name: "Organizations",
            to: { name: "Organizations" },
            exact: true,
          },
        ];
      },
    },
  },
  {
    path: "/resellers",
    name: "Resellers",
    component: () => import(/* webpackChunkName: "Resellers" */ "../views/ResellersPage.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "Resellers",
            name: "Resellers",
          },
        ];
      },
    },
  },
  {
    path: "/api-partners",
    name: "ApiPartners",
    component: () => import(/* webpackChunkName: "Affiliates" */ "../views/AffiliatesPage.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "ApiPartners",
            name: "API Partners",
          },
        ];
      },
    },
  },
  {
    path: "/api-partners/:id",
    name: "ApiPartner",
    component: () => import(/* webpackChunkName: "Affiliate" */ "../views/AffiliatePage.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            name: "API Partners",
            to: { name: "ApiPartners" },
            exact: true,
          },
        ];
      },
    },
  },
  {
    path: "/tenant/:tenantId",
    name: "Tenant",
    component: () => import("@/views/TenantPage.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            name: "API Partners",
            to: { name: "ApiPartners" },
            exact: true,
          },
          {
            name: "API Partner",
            to: { name: "ApiPartner" },
            exact: true,
          },
        ];
      },
    },
  },
  {
    path: "/white-label",
    name: "WhiteLabel",
    component: () => import(/* webpackChunkName: "WhiteLabelPage" */ "../views/WhiteLabelPage.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            routeName: "WhiteLabel",
            name: "White Label",
          },
        ];
      },
    },
  },
  {
    path: "/white-label/:id",
    name: "WhiteLabelPartner",
    component: () =>
      import(/* webpackChunkName: "WhiteLabelPartner" */ "../views/WhiteLabelPartnerPage.vue"),
    meta: {
      breadCrumb() {
        return [
          {
            name: "PG Dashboard",
            to: { name: "Home" },
            exact: true,
          },
          {
            name: "White Label",
            to: { name: "WhiteLabel" },
            exact: true,
          },
        ];
      },
    },
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
