import { profileApi, searchApi } from "@/plugins/axios";
import { AxiosRequestConfig } from "axios";
import { IFetchResponse, TError, TMethod } from "@/types/IFetchResponse";

export const callProfileApi = async <T>(
  method: TMethod,
  url: string,
  ...args: (AxiosRequestConfig | unknown)[]
): Promise<IFetchResponse<T>> => {
  try {
    const { data } = await profileApi[method](url, ...(args as AxiosRequestConfig[]));
    return { data, error: null };
  } catch (_error) {
    const { response } = _error as TError;
    return {
      data: null,
      error: {
        text: response?.data,
        status: response?.status,
      },
    };
  }
};

export const callSearchApi = async <T>(
  method: TMethod,
  url: string,
  ...args: (AxiosRequestConfig | unknown)[]
): Promise<IFetchResponse<T>> => {
  const versionedUrl = `/v1${url}`;

  try {
    const { data } = await searchApi[method](versionedUrl, ...(args as AxiosRequestConfig[]));
    return { data, error: null };
  } catch (_error) {
    const { response } = _error as TError;
    return {
      data: null,
      error: {
        text: response?.data,
        status: response?.status,
      },
    };
  }
};

export const callSearchApiV2 = async <T>(
  method: TMethod,
  url: string,
  ...args: (AxiosRequestConfig | unknown)[]
): Promise<IFetchResponse<T>> => {
  const versionedUrl = `/v2${url}`;

  try {
    const { data } = await searchApi[method](versionedUrl, ...(args as AxiosRequestConfig[]));
    return { data, error: null };
  } catch (_error) {
    const { response } = _error as TError;
    return {
      data: null,
      error: {
        text: response?.data,
        status: response?.status,
      },
    };
  }
};
