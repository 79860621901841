import { callProfileApi } from "@/api/apiHelper";
import { IFetchResponse } from "@/types/IFetchResponse";
import { IOrganization } from "@/types/IOrganization";
import { ICpt } from "@/types/ICpt";
import { IIcd } from "@/types/IIcd";
import { ITaxonomy } from "@/types/ITaxonomy";

const glossaryUrl = "/glossary";

export const getNppesOrgs = async (keyword: string): Promise<IFetchResponse<IOrganization[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/nppes-organizations`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};

export const getCptList = async (keyword: string): Promise<IFetchResponse<ICpt[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/cpts`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};

export const getIcdList = async (keyword: string): Promise<IFetchResponse<IIcd[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/icds`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};

export const getTaxonomyList = async (keyword: string): Promise<IFetchResponse<ITaxonomy[]>> => {
  return await callProfileApi("get", `${glossaryUrl}/taxonomies`, {
    params: {
      p: 0,
      pp: 50,
      k: keyword,
    },
  });
};
