import { IFetchResponse } from "@/types/IFetchResponse";
import { IAutocompleteResult } from "@/types/IAutocompleteResult";
import { callSearchApi } from "@/api/apiHelper";

export const getAutocompleteResults = async (
  keyword: string
): Promise<IFetchResponse<IAutocompleteResult[]>> => {
  return await callSearchApi("get", "/autocomplete", {
    params: { keyword },
  });
};
