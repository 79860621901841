import * as glossary from "./glossary";
import * as autocomplete from "./autocomplete";
import * as users from "./users";
import * as providers from "./providers";
import * as insurances from "./insurances";
import * as organizations from "./organizations";
import * as resellers from "./resellers";
import * as affiliates from "./affiliates";
import * as session from "./session";

export default {
  glossary,
  autocomplete,
  users,
  providers,
  insurances,
  organizations,
  resellers,
  affiliates,
  session,
};
