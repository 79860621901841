import { IProvidersRequest, IProvidersResponse } from "@/types/IProvider";
import { IFetchResponse } from "@/types/IFetchResponse";
import { callSearchApiV2 } from "@/api/apiHelper";

export const getProviders = async (
  params: IProvidersRequest
): Promise<IFetchResponse<IProvidersResponse>> => {
  return await callSearchApiV2("get", "/providers", {
    params,
  });
};
