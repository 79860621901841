import ax from "axios";
import { getAuth, getIdToken } from "firebase/auth";

const profileApi = ax.create({
  baseURL: `${process.env.VUE_APP_API_ADMIN_URL}`,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

profileApi.interceptors.request.use(
  async (config) => {
    if (process.env.VUE_APP_ADMIN_KEY) {
      config.headers["Admin-Key"] = process.env.VUE_APP_ADMIN_KEY;
    }

    const currentUser = getAuth().currentUser;
    if (!currentUser || !config.headers) return config;
    config.headers["X-Auth-Token"] = await getIdToken(currentUser);

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

profileApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

const searchApi = ax.create({
  baseURL: `${process.env.VUE_APP_API_SEARCH_URL}`,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

searchApi.interceptors.request.use(
  async (config) => {
    if (process.env.VUE_APP_ADMIN_KEY) {
      config.headers["Admin-Key"] = process.env.VUE_APP_ADMIN_KEY;
    }

    if (process.env.VUE_APP_PARTNER_KEY) {
      config.params = {
        ...config.params,
        key: process.env.VUE_APP_PARTNER_KEY,
      };
    }

    config.headers["x-session-token"] =
      document.cookie
        .split(";")
        .find((cookie) => cookie.trim().startsWith("sessionToken="))
        ?.split("=")[1] || "";

    return config;
  },

  (error) => Promise.reject(error)
);

searchApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

// Images  API
const imageApi = ax.create({
  baseURL: `${process.env.VUE_APP_IMG_API_URL}`,
  timeout: 15000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

imageApi.interceptors.request.use(
  async (config) => {
    if (process.env.VUE_APP_ADMIN_KEY) {
      config.headers["Admin-Key"] = process.env.VUE_APP_ADMIN_KEY;
    }

    const currentUser = getAuth().currentUser;
    if (!currentUser || !config.headers) return config;
    config.headers["X-Auth-Token"] = await getIdToken(currentUser);

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

imageApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export { profileApi, searchApi, imageApi };
